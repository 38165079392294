@import 'src/style/colors';
@import 'src/style/variables';

.menuBar {
    font-family: 'Kalam', cursive;
    font-weight: 300;
    font-size: 16px;
    margin: 0;
    padding: 0;
    a {
        text-decoration: none;
        font-weight: bold;
    }
}

img {
    max-width: 100%;
}

nav {
    width: 100%;
    background: $brand-primary-bg-color;
    a {
        color: $brand-primary-text-color;
        padding: 1rem;
    }
}

.content {
    padding: 1rem;
}

.container-fluid {
    padding: 0;
}

.navbar {
    padding: 0.1rem 0.5rem !important;
    border-bottom: solid 1px black;
}

.navbar-brand {
    margin-right: unset;
}

.flex-right {
    margin-left: auto;
}

.divider {
    margin: 1rem;
}

.siteTitle {
    padding: 0;
    display: flex;
    text-decoration: none !important;
}

.logoParent {
    width: 60px;
}

.bbLogo {
    max-width: 100%;
}

.bbUnderline {
    display: none;
}

.bbText {
    color: #fff500;
    font-size: 1.4em;
    padding-left: 0.2em;
    padding-top: 0.5em;
}

.menuItem {
    font-size: 1.3em;
    text-decoration: none !important;
}

@media (min-width: 768px) {
    .logoParent {
        width: 90px;
    }

    .bbText {
        font-size: 1.5em;
        padding-left: 0.5em;
        padding-top: 0.7em;
    }

    .bbUnderline {
        width: 170px;
        position: absolute;
        left: 6em;
        bottom: 1em;
        display: block;
    }
}
