@import 'src/style/colors';
@import 'src/style/variables';

.feature {
    font-size: 1.1em;
}

.featureTick {
    color: $brand-primary-text-color-alt;
    font-weight: 700;
    margin-right: 1em;
}
