$danger-red: #dc3545;
$grey-01: #999999;
$grey-02: #666666;

$brand-primary-bg-color: #3bb3c3;
$brand-primary-text-color: black;
$brand-primary-text-color-alt: #008585;

$brand-secondary-bg-color: #b82d17;
$brand-secondary-text-color: wheat;

$brand-tertiary-bg-color: #b82d17;
$brand-tertiary-text-color: white;

$border-color-grey: rgba(0, 0, 0, 0.1);
$border-color-grey-02: rgba(0, 0, 0, 0.03);

$star-rating-color: #fa9200;
