@import 'src/style/colors';
@import 'src/style/variables';

.title {
    font-size: $page-section-title-font-size;
    font-weight: 500;
    border-bottom: solid $border-thickness $border-color-grey;
    margin-bottom: 0.5em;
    padding: 0.25em 0;
}

.titleNoUnderline {
    font-size: $page-section-title-font-size;
    font-weight: 500;
}
