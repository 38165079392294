@import 'src/style/colors';
@import 'src/style/common';

.body {
    padding-top: 0.5em;
}

.paymentButtons {
    padding: 0;
    max-width: 250px;
}

.proforma {
    font-size: 1.25em;
}
