@import 'src/style/colors';
@import 'src/style/variables';
@import 'src/style/common';

.bolder {
    font-weight: 750;
}

.allFeatures {
    margin: 0.5em 0;
    font-size: 0.9em;
}

.feature {
    font-size: 1.1em;
    font-weight: 500;
}

.featureTick {
    color: $brand-primary-text-color-alt;
    font-weight: 700;
    margin-right: 1em;
}

.bookTitle {
    font-size: $book-title-font-size;
    font-weight: $book-title-font-weight;
}

.book {
    border: $border-color-grey solid $border-thickness;
    border-radius: $border-radius;
    padding: 1em;
}

.buttonGroup {
    margin: 0.5em 0;
}

.bookSubtitle {
    font-size: $book-subtitle-font-size;
}

.bookId {
    color: grey;
    font-size: 1em;
    margin-bottom: 0.5em;
}

.textKey {
    color: grey;
    font-size: 1em;
}

.textValue {
    color: black;
    font-size: 1.2em;
}

.priceValue {
    color: black;
    font-size: 2em;
}

.originalPrice {
    color: $danger-red;
    font-size: 1.2em;
}

.discountString {
    background-color: $danger-red;
    color: white;
    padding: 0.25em;
    border-radius: 0.25em;
}

.comingSoon {
    font-size: 2em;
    color: $danger-red;
}

.buyOnAmazonButton {
    max-height: 50px;
    border: solid #ffc107 2px;
    border-radius: 10px;
    padding: 0.1em;
}
