@import 'src/style/colors';
@import 'src/style/variables';

.footer {
    padding: 1em;
}

.dividerLine {
    border-bottom: solid 1px $border-color-grey;
}

.copyrightText {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1rem;
}

.supportEmail {
    margin-top: 0.5rem;
    text-align: center;
    font-size: 1rem;

    a {
        color: black;
        font-weight: normal;
    }
}

.disclaimer {
    font-size: 0.8em;
    margin: 0.7em;

    .title {
        color: $danger-red;
    }
}

.bbLogo {
    width: 5em;
}

.socialMedia {
    max-width: 32px;
}

.hyperLink {
    font-weight: normal;
}
