@import 'src/style/colors';
@import 'src/style/variables';
@import 'src/style/common';

.title {
    font-size: $page-section-title-font-size;
}

.subTitle {
    font-size: 1.2em;
}

.privacyTitle {
    font-size: 1.1em;
}
