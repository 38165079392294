.parent {
    padding: 0.5em;
    overflow: hidden;
    .menuItems {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        Link {
            padding-right: 1em;
        }

        a {
            color: black;
            font-weight: normal !important;
            padding-right: 1em;
        }
    }

    @media (max-width: 600px) {
        .menuItems {
            justify-content: start;
        }
    }
}
